// 客户税费
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机车牌">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款状态">
          <el-select class="item-choose" v-model="dataForm.textsFeeWriteStatus"
                     size="mini" style="width: 100%" filterable clearable placeholder="请选择">
            <el-option value="1" label="已付款"/>
            <el-option value="2" label="未付款"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
          <el-button @click="getLatestData(1)" type="primary">获取最新数据</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('customer:receivable:update')" @click="openWriteOffPopup(1)"
                       :disabled="!selectFeeData.length">已付款核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('customer:receivable:update')" @click="batchAudit(2)"
                       :disabled="!selectFeeData.length">反核销
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick" :row-style="selectedHighlight"
                :height="tableHeight" border size="small" v-loading="dataListLoading"
                @selection-change="selectionChangeHandle" style="width: 100%;" class="table-input">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column type="index" label="序号" >
        </el-table-column>
        <el-table-column prop="orderInfo.customerName" label="客户名称" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="orderInfo.takeGoodsDate" label="提货时间" width="110" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单车型" :formatter="dictConvert" width="80" :show-overflow-tooltip="true" prop="orderInfo.vehicleType">
        </el-table-column>
        <el-table-column prop="fullAddress" label="提货地址" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="giveAddressInfo.fullAddress" label="卸货地址" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="costInfo.freightFee" label="运费" width="100" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="orderInfo.taxesFeePercentage" label="税率(%)" width="100" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="orderInfo.taxesFee" label="税费(元)" width="100" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column label="费用小计" prop="payableAmount" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="paidAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="notPayableAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款状态" prop="textsFeeWriteStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.textsFeeWriteStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.orderInfo.textsFeeWriteStatus == 2" size="small" type="danger">未付款</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dataForm: {
        customerName: null,
        vehiclePlate: null,
        takeGoodsTime: [],
        textsFeeWriteStatus: null
      },
      tableHeight: '65vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      detailVisible: false,
      isEditMoney: -1,
      selectFeeData: [],
      initEditMoney: {}
    }
  },
  components: {
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    window.setTimeout(() => {
      this.tableHeight = '67vh'
    }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    openWriteOffPopup (textsFeeWriteStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.textsFeeWriteStatus !== textsFeeWriteStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      // if (ids.length === 0) {
      //   this.$message.error('没有符合核销的数据，请检查是否勾选正确')
      //   return
      // }
      let title = '费用核销'
      if (textsFeeWriteStatus === 1) {
        title = '已付款核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/costInfo/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            textsFeeWriteStatus: textsFeeWriteStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
      // this.$refs.writeOff.init(this.dataForm.customerName, textsFeeWriteStatus, ids)
    },
    batchAudit (textsFeeWriteStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.textsFeeWriteStatus !== textsFeeWriteStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (textsFeeWriteStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/costInfo/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            textsFeeWriteStatus: textsFeeWriteStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property) {
            if (column.property === 'orderInfo.taxesFee') {
              return this.getValue(Number(item.orderInfo.taxesFee))
            }
            if (column.property === 'costInfo.freightFee') {
              return this.getValue(Number(item.costInfo.freightFee))
            }
            if (column.property === 'payableAmount') {
              return this.getValue(Number(item.payableAmount))
            }
            if (column.property === 'paidAmount') {
              return this.getValue(Number(item.paidAmount))
            }
            if (column.property === 'notPayableAmount') {
              return this.getValue(Number(item.notPayableAmount))
            }
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        totalSummary.freightFee,
        '',
        totalSummary.taxesFee,
        totalSummary.payableAmount,
        totalSummary.paidAmount,
        totalSummary.notPayableAmount, '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    // 历史记录
    history (row) {
      /**
       * 1 = 应收 ，2 = 应付
       */
      this.$refs.history.init(row, 1)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/findCustomerTaxesFeeList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'textsFeeWriteStatus': this.dataForm.textsFeeWriteStatus
        })
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.summaryTotal || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 计算客户维护费
    getLatestData (status) {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/updateCustomerTaxesFee'),
        method: 'post',
        data: this.$http.adornParams({
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/costInfo/exportCustomerTaxesFee`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '客户税费.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
      this.selectFeeData = val
    }
  }
}
</script>
<style lang="less">
span.searchUpload {
    display: inline-block;
    margin-left: 10px;
}
</style>
